import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import {
  Routes,
  Route,
  Outlet,
  Link,
  BrowserRouter,
  HashRouter
} from "react-router-dom";

import Home from "./components/Home";

var home_link = "/";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={home_link} element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
