import { useMediaQuery } from "react-responsive";
import homeoption_image from "../assets/home_option_image.png";
import politicalasia_logo from "../assets/politicalasia_logo.png";

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)"
  });
  return (
    <div className="main-container">
      <div className="home-outer-container">
        {/* <div className="home-text-style">{"electoral."}</div>
          <div className="home-text-style">{"campaign."}</div>
          <div className="home-text-style">{"power."}</div> */}
        <div>
          <img
            src={homeoption_image}
            alt="img-home-logo"
            className="img-home-option-logo"
          />
          <img
            src={politicalasia_logo}
            alt="img-home-logo"
            className="img-home-logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
